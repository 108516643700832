var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"match-height mt-4"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header d-flex justify-content-between border-bottom"},[_c('h4',{staticClass:"card-title"},[_vm._v("Blogs")]),_c('div',[_c('router-link',{staticClass:"btn-primary btn",attrs:{"to":{
                                name: 'apps-blogs-create',
                            }}},[_vm._v(" Create ")])],1)]),_c('div',{staticClass:"card-datatable table-responsive"},[_c('table',{staticClass:"datatables-ajax table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("SN")]),_c('th',[_vm._v("Title")]),_c('th',[_vm._v("Short Description")]),_c('th',[_vm._v("Category")]),_c('th',[_vm._v("Action")])])]),_c('tfoot',[_c('tr',[_c('th',[_vm._v("SN")]),_c('th',[_vm._v("Title")]),_c('th',[_vm._v("Short Description")]),_c('th',[_vm._v("Category")]),_c('th',[_vm._v("Action")])])]),_c('tbody',_vm._l((_vm.blogs),function(blog,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_c('router-link',{attrs:{"to":{
                                            name: 'apps-blogs-update',
                                            params: { id: blog.slug },
                                        }}},[_vm._v(" "+_vm._s(blog.title)+" ")])],1),_c('td',[_vm._v(_vm._s(blog.short_description))]),_c('td',[_vm._v(_vm._s(blog.category.name))]),_c('td',[_c('b-dropdown',{attrs:{"variant":"link","no-caret":"","right":_vm.$store.state.appConfig.isRTL},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"to":{
                                                name: 'apps-blogs-update',
                                                params: { id: blog.slug },
                                            }}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Edit")])],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteAccount(blog.slug)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Delete")])],1)],1)],1)])}),0)])])])])]),_c('div',{staticClass:"mx-2 mb-2"},[_c('nav',{attrs:{"aria-label":"Page navigation"}},[_c('ul',{staticClass:"pagination"},_vm._l((_vm.links),function(link){return _c('li',{key:link.id,class:link.active ? 'page-item active' : 'page-item'},[_c('a',{staticClass:"page-link",attrs:{"href":"javascript:;"},domProps:{"innerHTML":_vm._s(link.label)},on:{"click":function($event){return _vm.paginate(link.url)}}})])}),0)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }